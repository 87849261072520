<!-- Preloader Part Start -->
<div class="preload-main">
    <div class='preloader'>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </div>
</div>
<!-- Preloader Part End -->
<!-- HEADER AREA START -->
<nav class="navbar navbar-expand-lg navbar-light bg-light sticky-top">
    <div class="container">
        <a class="navbar-brand" href="#banner"><b>Neshorn Technologies</b></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <i class="fa fa-bars" aria-hidden="true"></i>
        </button>

        <div class="collapse navbar-collapse menu-main" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto menu-item">
                <li class="nav-item">
                    <a class="nav-link" href="#banner">Home</a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" href="#about">About Us</a>
              </li> -->
                <li class="nav-item">
                    <a class="nav-link" href="#portfolio">Our Portfolio</a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link" href="#service">Our Service</a>
              </li> -->
                <li class="nav-item">
                    <a class="nav-link" href="#team">Our Team</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#contact">Contact Us</a>
                </li>
            </ul>
        </div>
    </div>
</nav>
<!-- HEADER AREA END -->

<!-- BANNER AREA START -->
<section id="banner">
    <!-- <i class="fa fa-long-arrow-left slidPrv" aria-hidden="true"></i>
  <i class="fa fa-long-arrow-right slidNext" aria-hidden="true"></i> -->
    <div class="slider-img">
        <div class="slide-banner" style="background: url(assets/image/corporate-business-red.jpg)">
            <div class="overly">
                <div class="banner-content">
                    <div class="container text-center">
                        <div class="banner-text">
                            <!-- <h2>WE ARE</h2> -->
                            <img src="assets/image/logo.png" alt="Logo" class="banner-logo img-fluid">
                            <h3>Neshorn Technologies</h3>
                            <p>Simplifying as we go.</p>
                            <a class="ban-btn" href="#contact">Contact us</a>
                            <div class="social-icon text-center">
                                <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                <!-- <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a> -->
                                <a href="mailto:hi@neshorntech.com"><i class="fa fa-envelope"
                                        aria-hidden="true"></i></a>
                                <a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="slide-banner" style="background: url(assets/image/banner.jpg)">
          <div class="overly">
              <div class="banner-content">
                  <div class="container text-center">
                      <div class="banner-text">
                          <h2>WE ARE THE MOST</h2>
                          <h3>AWESOME AGENCY</h3>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                          <a class="ban-btn" href="#">Let's Get Start</a>
                          <div class="social-icon text-center">
                              <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                              <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                              <a href="#"><i class="fa fa-google-plus" aria-hidden="true"></i></a>
                              <a href="#"><i class="fa fa-dribbble" aria-hidden="true"></i></a>
                              <a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="slide-banner" style="background: url(assets/image/banner2.jpg)">
          <div class="overly">
              <div class="banner-content">
                  <div class="container text-center">
                      <div class="banner-text">
                          <h2>WE ARE THE MOST</h2>
                          <h3>POWERFUL COMPANY</h3>
                          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text.</p>
                          <a class="ban-btn" href="#">Let's Get Start</a>
                          <div class="social-icon text-center">
                              <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                              <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                              <a href="#"><i class="fa fa-google-plus" aria-hidden="true"></i></a>
                              <a href="#"><i class="fa fa-dribbble" aria-hidden="true"></i></a>
                              <a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div> -->
    </div>
</section>
<!-- BANNER AREA END -->

<!-- MARKET AREA START -->
<!-- <div id="market">
  <div class="container">
      <div class="row">
          <div class="col-lg-12">
              <div class="market-main">
                  <div class="col-lg-3">
                      <div class="market-item">
                          <img src="assets/image/3docean.png" alt="company-img" class="img-fluid">
                      </div>
                  </div>
                  <div class="col-lg-3">
                      <div class="market-item">
                          <img src="assets/image/audiojungle.png" alt="company-img" class="img-fluid">
                      </div>
                  </div>
                  <div class="col-lg-3">
                      <div class="market-item">
                          <img src="assets/image/codecanyon.png" alt="company-img" class="img-fluid">
                      </div>
                  </div>
                  <div class="col-lg-3">
                      <div class="market-item">
                          <img src="assets/image/theneforst.png" alt="company-img" class="img-fluid">
                      </div>
                  </div>
                  <div class="col-lg-3">
                      <div class="market-item">
                          <img src="assets/image/audiojungle.png" alt="company-img" class="img-fluid">
                      </div>
                  </div>
                  <div class="col-lg-3">
                      <div class="market-item">
                          <img src="assets/image/photodune.png" alt="company-img" class="img-fluid">
                      </div>
                  </div>
                  <div class="col-lg-3">
                      <div class="market-item">
                          <img src="assets/image/theneforst.png" alt="company-img" class="img-fluid">
                      </div>
                  </div>
                  <div class="col-lg-3">
                      <div class="market-item">
                          <img src="assets/image/videohive.png" alt="company-img" class="img-fluid">
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div> -->
<!-- MARKET AREA START -->

<!-- ABOUT AREA START -->
<!-- <section id="about">
  <div class="backtotop">
      <a href="#banner"><i class="fa fa-chevron-up" aria-hidden="true"></i></a>
  </div>
  <div class="container">
      <div class="row">
          <div class="col-lg-12 about-head">
              <h2>ABOUT US</h2>
              <h3>KNOW ABOUT OUR COMPANY</h3>
          </div>
      </div>
      <div class="row apt">
          <div class="col-lg-5 col-sm-8 m-sm-auto">
              <div class="about-img">
                  <img src="assets/image/about2.jpg" alt="about-img" class="img-fluid">
              </div>
          </div>
          <div class="col-lg-1"></div>
          <div class="col-lg-6 about-text">
              <h2>Our company name is Neshorn Technologies</h2>
              <span>From United State, America</span>
              <div class="about-p">
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum, nisi sed deleniti ex nostrum qui veritatis necessitatibus quod, ea reiciendis saepe est culpa! Dolor debitis, suscipit tenetur inventore, magnam est iure aliquam vel sint dolore libero architecto impedit accusamus eos dolorum autem voluptatem, assumenda commodi minus omnis quasi eum veniam?</p>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fuga nostrum deleniti consequuntur, soluta corporis similique.</p>
              </div>
              <div class="row counter-main">
                  <div class="counter-1 col-lg-3 col-sm-6 col-md-3">
                      <div class="chart" data-percent="75">
                      </div>
                      <h3>95%</h3>
                      <h4>Projects</h4>
                  </div>
                  <div class="counter-1 col-lg-3  col-sm-6 col-md-3">
                      <div class="chart" data-percent="65">
                      </div>
                      <h3>80%</h3>
                      <h4>Satisfaction</h4>
                  </div>
                  <div class="counter-1 col-lg-3 col-sm-6 col-md-3">
                      <div class="chart" data-percent="55">
                      </div>
                      <h3>75%</h3>
                      <h4>Rating</h4>
                  </div>
                  <div class="counter-1 col-lg-3 col-sm-6 col-md-3">
                      <div class="chart" data-percent="45">
                      </div>
                      <h3>60%</h3>
                      <h4>New Skills</h4>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section> -->
<!-- ABOUT AREA END -->

<!-- PROTFOLIO AREA START -->
<section id="portfolio" class="portfolio-bg">
    <div class="port-overly"></div>
    <div class="container">
        <div class="row">
            <div class="col-lg-12 about-head heading-bg latest-works">
                <h2>OUR PRODUCTS</h2>
                <h3>SEE OUR LATEST WORKS</h3>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="portfolio-imag">
                    <div class="col-md-3">
                        <div class="portfolio-inner">
                            <!-- <div class="port-img">
                              <div class="port-overlay">
                                  <a class="venobox" data-gall="gallery01" href="assets/image/port-big1.jpg"><i class="fa fa-search-plus"></i>
                                  </a>
                              </div>
                              <img src="assets/image/port1.png" alt="port1" class="img-fluid">
                          </div> -->
                            <div class="port-img gamesurv-project">
                                <div class="port-overlay">
                                    <a class="venobox" href="https://gamesurv.com" target="_blank"><i
                                            class="fa fa-search-plus"></i></a></div>
                                <img src="assets/projects/game-surv-logo-name-and-slogan.svg" alt="port2"
                                    class="img-fluid">
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-md-3">
                      <div class="portfolio-inner">
                          <div class="port-img">
                              <div class="port-overlay">
                                  <a class="venobox" data-gall="gallery01" href="assets/image/port-big3.jpg"><i class="fa fa-search-plus"></i></a>
                              </div>
                              <img src="assets/image/port3.png" alt="port1" class="img-fluid">
                          </div>
                          <div class="port-img">
                              <div class="port-overlay">
                                  <a class="venobox" data-gall="gallery01" href="assets/image/port-big4.jpg"><i class="fa fa-search-plus"></i></a>
                              </div>
                              <img src="assets/image/port4.png" alt="port2" class="img-fluid">
                          </div>
                      </div>
                  </div>
                  <div class="col-md-3">
                      <div class="portfolio-inner">
                          <div class="port-img">
                              <div class="port-overlay">
                                  <a class="venobox" data-gall="gallery01" href="assets/image/port-big5.jpg"><i class="fa fa-search-plus"></i></a>
                              </div>
                              <img src="assets/image/port5.png" alt="port1" class="img-fluid">
                          </div>
                          <div class="port-img">
                              <div class="port-overlay">
                                  <a class="venobox" data-gall="gallery01" href="assets/image/port-big6.jpg"><i class="fa fa-search-plus"></i></a>
                              </div>
                              <img src="assets/image/port6.png" alt="port2" class="img-fluid">
                          </div>
                      </div>
                  </div>
                  <div class="col-md-3">
                      <div class="portfolio-inner">
                          <div class="port-img">
                              <div class="port-overlay">
                                  <a class="venobox" data-gall="gallery01" href="assets/image/port-big7.jpg"><i class="fa fa-search-plus"></i></a>
                              </div>
                              <img src="assets/image/port7.png" alt="port1" class="img-fluid">
                          </div>
                          <div class="port-img">
                              <div class="port-overlay">
                                  <a class="venobox" data-gall="gallery01" href="assets/image/port-big8.jpg"><i class="fa fa-search-plus"></i></a>
                              </div>
                              <img src="assets/image/port8.png" alt="port2" class="img-fluid">
                          </div>
                      </div>
                  </div>
                  <div class="col-md-3">
                      <div class="portfolio-inner">
                          <div class="port-img">
                              <div class="port-overlay">
                                  <a class="venobox" data-gall="gallery01" href="assets/image/port-big1.jpg">
                                      <i class="fa fa-search-plus"></i></a>
                              </div>
                              <img src="assets/image/port1.png" alt="port1" class="img-fluid">
                          </div>
                          <div class="port-img">
                              <div class="port-overlay">
                                  <a class="venobox" data-gall="gallery01" href="assets/image/port-big2.jpg"><i class="fa fa-search-plus"></i></a>
                              </div>
                              <img src="assets/image/port2.png" alt="port2" class="img-fluid">
                          </div>
                      </div>
                  </div>
                  <div class="col-md-3">
                      <div class="portfolio-inner">
                          <div class="port-img">
                              <div class="port-overlay">
                                  <a class="venobox" data-gall="gallery01" href="assets/image/port-big3.jpg"><i class="fa fa-search-plus"></i></a>
                              </div>
                              <img src="assets/image/port3.png" alt="port1" class="img-fluid">
                          </div>
                          <div class="port-img">
                              <div class="port-overlay">
                                  <a class="venobox" data-gall="gallery01" href="assets/image/port-big4.jpg"><i class="fa fa-search-plus"></i></a>
                              </div>
                              <img src="assets/image/port4.png" alt="port2" class="img-fluid">
                          </div>
                      </div>
                  </div> -->
                </div>
            </div>
        </div>
    </div>
</section>
<!-- PROTFOLIO AREA END -->

<!-- SERVICE AREA START -->
<!-- <section id="service">
  <div class="container">
      <div class="row">
          <div class="col-lg-4 service-p">
              <div class="about-head">
                  <h2>OUR SERVICE</h2>
                  <h3>CHECKOUT OUR SERVICE</h3>
              </div>
              <div class="service-details">
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officia quaerat accusantium, voluptate tempore natus ipsam minima tenetur et sed debitis, veniam cum illum, hic aperiam saepe! Voluptates, illum obcaecati modi blanditiis eius pariatur officiis veniam.</p>
                  <p>quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                  <a href="#footer">Start Working Now</a>
              </div>
          </div>
          <div class="col-lg-4 col-sm-6">
              <div class="service-item">
                  <div class="service-icon">
                      <i class="fa fa-file-code-o" aria-hidden="true"></i>
                  </div>
                  <h3>Web <span>Design</span></h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adiing elit, sed do eiusmod tempor incididunt utabore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
              </div>
              <div class="service-item service-item2">
                  <div class="service-icon">
                      <i class="fa fa-line-chart" aria-hidden="true"></i>
                  </div>
                  <h3>Digital <span>Marketing</span> </h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adiing elit, sed do eiusmod tempor incididunt utabore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
              </div>
          </div>
          <div class="col-lg-4 col-sm-6">
              <div class="service-item service-item3">
                  <div class="service-icon">
                      <i class="fa fa-cogs" aria-hidden="true"></i>
                  </div>
                  <h3>Web <span>Development</span></h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adiing elit, sed do eiusmod tempor incididunt utabore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
              </div>
              <div class="service-item service-item4">
                  <div class="service-icon">
                      <i class="fa fa-tablet" aria-hidden="true"></i>
                  </div>
                  <h3>Apps <span>Development</span></h3>
                  <p>Lorem ipsum dolor sit amet, consectetur adiing elit, sed do eiusmod tempor incididunt utabore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation.</p>
              </div>
          </div>
      </div>
  </div>
</section> -->
<!-- SERVICE AREA END -->

<!-- TEAM AREA START -->
<section id="team">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 about-head">
                <h2>WHO WE ARE</h2>
                <h3>BEST TEAM RIGHT NOW</h3>
            </div>
        </div>
        <div class="row apt">
            <div class="col-lg-3 col-sm-6">
                <!-- <div class="team-item">
                  <div class="team-overlay text-center">
                      <div class="overlay-text">
                          <h3>Shakib</h3>
                          <p>Web Designer</p>
                      </div>
                      <div class="t-icon">
                          <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                          <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                          <a href="#"><i class="fa fa-google-plus" aria-hidden="true"></i></a>
                      </div>
                  </div>
                  <img src="assets/image/team1.jpg" alt="team-img" class="img-fluid w-100">
              </div> -->
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="team-item">
                    <div class="team-overlay text-center">
                        <div class="overlay-text">
                            <h3>Paul</h3>
                            <p>Director</p>
                        </div>
                        <div class="t-icon">
                            <a href="https://www.linkedin.com/in/paul-booyens-18ba7516b/"><i class="fa fa-linkedin"
                                    aria-hidden="true"></i></a>
                            <a href="mailto:paul@neshorntech.com"><i class="fa fa-envelope" aria-hidden="true"></i></a>
                        </div>
                    </div>
                    <img src="assets/image/paul.png" alt="team-img" class="img-fluid w-100">
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="team-item">
                    <div class="team-overlay text-center">
                        <div class="overlay-text">
                            <h3>Dirko</h3>
                            <p>Director</p>
                        </div>
                        <div class="t-icon">
                            <a href="https://www.linkedin.com/in/dirko-odendaal-93357550/"><i class="fa fa-linkedin"
                                    aria-hidden="true"></i></a>
                            <a href="mailto:dirko@neshorntech.com"><i class="fa fa-envelope"
                                    aria-hidden="true"></i></a>
                        </div>
                    </div>
                    <img src="assets/image/dirko.png" alt="team-img" class="img-fluid w-100">
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <!-- <div class="team-item">
                  <div class="team-overlay text-center">
                      <div class="overlay-text">
                          <h3>Alex</h3>
                          <p>App Developer</p>
                      </div>
                      <div class="t-icon">
                          <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                          <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                          <a href="#"><i class="fa fa-google-plus" aria-hidden="true"></i></a>
                      </div>
                  </div>
                  <img src="assets/image/team3.jpg" alt="team-img" class="img-fluid w-100">
              </div> -->
            </div>
        </div>
    </div>
</section>
<!-- TEAM AREA END -->

<!-- COUNT AREA START -->
<section id="count">
    <div class="container zindex">
        <div class="row">
            <div class="col-lg-3 col-sm-6 text-center">
                <div class="count-item">
                    <div class="count-icon">
                        <i class="fa fa-comment" aria-hidden="true"></i>
                    </div>
                    <div class="count-text">
                        <h3>Meetings <span>Held</span></h3>
                        <p class="counter" data-counterup-time="1500" data-counterup-delay="30"
                            data-counterup-beginat="100">{{376 | number}}</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 text-center">
                <div class="count-item">
                    <div class="count-icon">
                        <i class="fa fa-clock-o" aria-hidden="true"></i>
                    </div>
                    <div class="count-text">
                        <h3>Hours <span>Worked</span></h3>
                        <p class="counter" data-counterup-time="1500" data-counterup-delay="30"
                            data-counterup-beginat="100">{{3542083 | number}}</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 text-center">
                <div class="count-item">
                    <div class="count-icon">
                        <i class="fa fa-coffee" aria-hidden="true"></i>
                    </div>
                    <div class="count-text">
                        <h3>Coffee <span>Taken</span></h3>
                        <p class="counter" data-counterup-time="1500" data-counterup-delay="30"
                            data-counterup-beginat="100">{{73399 | number}}</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6 text-center">
                <div class="count-item">
                    <div class="count-icon">
                        <i class="fa fa-laptop" aria-hidden="true"></i>
                    </div>
                    <div class="count-text">
                        <h3>Laptops <span>Broken</span></h3>
                        <p class="counter" data-counterup-time="1500" data-counterup-delay="30"
                            data-counterup-beginat="100">3</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- COUNT AREA END -->

<!-- PRICE AREA START -->
<!-- <section id="price">
  <div class="container">
      <div class="row">
          <div class="col-lg-12 about-head">
              <h2>PRICE PLAN</h2>
              <h3>GET THINGS DONE EASILY</h3>
          </div>
      </div>
  </div>
</section>
<section id="price2">
  <div class='pricing pricing-palden'>
      <div class='pricing-item'>
          <div class='pricing-deco'>
              <svg class='pricing-deco-img' enable-background='new 0 0 300 100' height='100px' preserveAspectRatio='none' version='1.1' viewBox='0 0 300 100' width='300px' x='0px' xml:space='preserve' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns='http://www.w3.org/2000/svg' y='0px'>
                  <path class='deco-layer deco-layer--1' d='M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729&#x000A;	c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z' fill='#FFFFFF' opacity='0.6'></path>
                  <path class='deco-layer deco-layer--2' d='M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729&#x000A;	c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z' fill='#FFFFFF' opacity='0.6'></path>
                  <path class='deco-layer deco-layer--3' d='M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716&#x000A;	H42.401L43.415,98.342z' fill='#FFFFFF' opacity='0.7'></path>
                  <path class='deco-layer deco-layer--4' d='M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428&#x000A;	c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z' fill='#FFFFFF'></path>
              </svg>
              <div class='pricing-price'><span class='pricing-currency'>$</span>15
                  <span class='pricing-period'>/ mo</span>
              </div>
              <h3 class='pricing-title'>Freelance</h3>
          </div>
          <ul class='pricing-feature-list'>
              <li class='pricing-feature'>1 GB of space</li>
              <li class='pricing-feature'>Support at $25/hour</li>
              <li class='pricing-feature'>Limited cloud access</li>
          </ul>
          <a href="#" class='pricing-action'>Order Now</a>
      </div>
      <div class='pricing-item pricing__item--featured'>
          <div class='pricing-deco'>
              <svg class='pricing-deco-img' enable-background='new 0 0 300 100' height='100px' preserveAspectRatio='none' version='1.1' viewBox='0 0 300 100' width='300px' x='0px' xml:space='preserve' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns='http://www.w3.org/2000/svg' y='0px'>
                  <path class='deco-layer deco-layer--1' d='M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729&#x000A;	c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z' fill='#FFFFFF' opacity='0.6'></path>
                  <path class='deco-layer deco-layer--2' d='M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729&#x000A;	c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z' fill='#FFFFFF' opacity='0.6'></path>
                  <path class='deco-layer deco-layer--3' d='M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716&#x000A;	H42.401L43.415,98.342z' fill='#FFFFFF' opacity='0.7'></path>
                  <path class='deco-layer deco-layer--4' d='M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428&#x000A;	c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z' fill='#FFFFFF'></path>
              </svg>
              <div class='pricing-price'><span class='pricing-currency'>$</span>30
                  <span class='pricing-period'>/ mo</span>
              </div>
              <h3 class='pricing-title'>Business</h3>
          </div>
          <ul class='pricing-feature-list'>
              <li class='pricing-feature'>5 GB of space</li>
              <li class='pricing-feature'>Support at $5/hour</li>
              <li class='pricing-feature'>Full cloud access</li>
          </ul>
          <a href="#" class='pricing-action pa-act'>Order Now</a>
      </div>
      <div class='pricing-item'>
          <div class='pricing-deco'>
              <svg class='pricing-deco-img' enable-background='new 0 0 300 100' height='100px' preserveAspectRatio='none' version='1.1' viewBox='0 0 300 100' width='300px' x='0px' xml:space='preserve' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns='http://www.w3.org/2000/svg' y='0px'>
                  <path class='deco-layer deco-layer--1' d='M30.913,43.944c0,0,42.911-34.464,87.51-14.191c77.31,35.14,113.304-1.952,146.638-4.729&#x000A;	c48.654-4.056,69.94,16.218,69.94,16.218v54.396H30.913V43.944z' fill='#FFFFFF' opacity='0.6'></path>
                  <path class='deco-layer deco-layer--2' d='M-35.667,44.628c0,0,42.91-34.463,87.51-14.191c77.31,35.141,113.304-1.952,146.639-4.729&#x000A;	c48.653-4.055,69.939,16.218,69.939,16.218v54.396H-35.667V44.628z' fill='#FFFFFF' opacity='0.6'></path>
                  <path class='deco-layer deco-layer--3' d='M43.415,98.342c0,0,48.283-68.927,109.133-68.927c65.886,0,97.983,67.914,97.983,67.914v3.716&#x000A;	H42.401L43.415,98.342z' fill='#FFFFFF' opacity='0.7'></path>
                  <path class='deco-layer deco-layer--4' d='M-34.667,62.998c0,0,56-45.667,120.316-27.839C167.484,57.842,197,41.332,232.286,30.428&#x000A;	c53.07-16.399,104.047,36.903,104.047,36.903l1.333,36.667l-372-2.954L-34.667,62.998z' fill='#FFFFFF'></path>
              </svg>
              <div class='pricing-price'><span class='pricing-currency'>$</span>60
                  <span class='pricing-period'>/ mo</span>
              </div>
              <h3 class='pricing-title'>Enterprise</h3>
          </div>
          <ul class='pricing-feature-list'>
              <li class='pricing-feature'>10 GB of space</li>
              <li class='pricing-feature'>Support at $5/hour</li>
              <li class='pricing-feature'>Full cloud access</li>
          </ul>
          <a href="#" class='pricing-action'>Order Now</a>
      </div>
  </div>
</section> -->
<!-- PRICE AREA END -->

<!-- CONTACT AREA START -->
<section id="contact">
    <div class="container zindex">
        <div class="row">
            <div class="col-lg-12 about-head heading-bg form-head">
                <h2>CONTACT US</h2>
                <h3>STAY CONNETED EVERYTIME</h3>
            </div>
        </div>
    </div>
    <div class="container zindex">
        <div class="row">
            <div class="col-lg-12 form-p">
                <div class="user-form">
                    <form class="contact-form" [formGroup]="contactUsForm">
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="exampleInputEmail1"> <i class="fa fa-user-o" aria-hidden="true"></i>
                                        Your first name</label>
                                    <input formControlName="name" type="text" class="form-control" placeholder="Name">
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="form-group">
                                    <label for="exampleInputEmail1"> <i class="fa fa-user-o" aria-hidden="true"></i>
                                        Your last name</label>
                                    <input formControlName="surname" type="text" class="form-control" placeholder="Surname">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1"><i class="fa fa-envelope-o" aria-hidden="true"></i>
                                        Your email address</label>
                                    <input formControlName="email" type="email" class="form-control" placeholder="Email">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="form-group">
                                    <label for="exampleFormControlTextarea1"><i class="fa fa-comments-o"
                                            aria-hidden="true"></i> Message</label>
                                    <textarea formControlName="message" class="form-control" id="exampleFormControlTextarea1" rows="3"
                                        placeholder="Type here...."></textarea>
                                </div>
                            </div>
                        </div>
                        <button *ngIf="!messageSent" [disabled]="busySending || contactUsForm.invalid"
                            (click)="sendContactUsEmail()" type="button" class="btn btn-primary"><i
                                class="fa fa-paper-plane-o" aria-hidden="true"></i> Send It
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"
                                *ngIf="busySending"></span></button>
                        <div *ngIf="messageSent" class="message-sent">
                            <p>Thank you for the message. We will get back to you as soon as possible.</p>
                        </div>
                    </form>
                </div>
            </div>
            <!-- <div class="col-lg-6 form-icon text-center">
              <a class="venobox" data-autoplay="true" data-vbtype="video" href="https://www.youtube.com/watch?v=nuktTVmoKfc"><i class="fa fa-play" aria-hidden="true"></i></a>
              <div class="watch-text">
                  <a class="venobox" data-autoplay="true" data-vbtype="video" href="https://www.youtube.com/watch?v=nuktTVmoKfc">Watch Our Story</a>
              </div>
          </div> -->
        </div>
    </div>
</section>
<!-- CONTACT AREA END -->

<!-- TESTIMONIAL AREA START -->
<!-- <section id="testimonial">
  <div class="container">
      <div class="row">
          <div class="col-lg-12 about-head">
              <h2>OUR TESTIMONIAL</h2>
              <h3>LOOK WHAT PEOPLE SAYS</h3>
          </div>
      </div>
      <div class="row pt-5">
          <div class="col-lg-12">
              <div class="news-main">
                  <div class="col-lg-6 col-md-6">
                      <div class="news-item-main">
                          <div class="news-items">
                              <div class="testimonial-text">
                                  <p><i class="fa fa-quote-left" aria-hidden="true"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, veritatis, aut. Aperiam quas sunt numquam, quos eaque</p>
                              </div>
                              <div class="testimonial-img">
                                  <div class="user-img">
                                      <img src="assets/image/testimonial2.png" alt="user-img">
                                  </div>
                                  <div class="testimonial-img-text">
                                      <i class="fa fa-star" aria-hidden="true"></i>
                                      <i class="fa fa-star" aria-hidden="true"></i>
                                      <i class="fa fa-star" aria-hidden="true"></i>
                                      <i class="fa fa-star" aria-hidden="true"></i>
                                      <i class="fa fa-star-half-o" aria-hidden="true"></i>
                                      <h4>Shakibul A.</h4>
                                      <span>3 jan, 2019</span>
                                  </div>
                              </div>
                              <div class="clr"></div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                      <div class="news-item-main">
                          <div class="news-items">
                              <div class="testimonial-text">
                                  <p><i class="fa fa-quote-left" aria-hidden="true"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, veritatis, aut. Aperiam quas sunt numquam, quos eaque</p>
                              </div>
                              <div class="testimonial-img">
                                  <div class="user-img">
                                      <img src="assets/image/testimonial1.png" alt="user-img">
                                  </div>
                                  <div class="testimonial-img-text">
                                      <i class="fa fa-star" aria-hidden="true"></i>
                                      <i class="fa fa-star" aria-hidden="true"></i>
                                      <i class="fa fa-star" aria-hidden="true"></i>
                                      <i class="fa fa-star" aria-hidden="true"></i>
                                      <i class="fa fa-star-half-o" aria-hidden="true"></i>
                                      <h4>Mrs Jerry</h4>
                                      <span>3 jan, 2019</span>
                                  </div>
                              </div>
                              <div class="clr"></div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                      <div class="news-item-main">
                          <div class="news-items">
                              <div class="testimonial-text">
                                  <p><i class="fa fa-quote-left" aria-hidden="true"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, veritatis, aut. Aperiam quas sunt numquam, quos eaque</p>
                              </div>
                              <div class="testimonial-img">
                                  <div class="user-img">
                                      <img src="assets/image/testimonial2.png" alt="user-img">
                                  </div>
                                  <div class="testimonial-img-text">
                                      <i class="fa fa-star" aria-hidden="true"></i>
                                      <i class="fa fa-star" aria-hidden="true"></i>
                                      <i class="fa fa-star" aria-hidden="true"></i>
                                      <i class="fa fa-star" aria-hidden="true"></i>
                                      <i class="fa fa-star-half-o" aria-hidden="true"></i>
                                      <h4>Shuvo Kiron</h4>
                                      <span>3 jan, 2019</span>
                                  </div>
                              </div>
                              <div class="clr"></div>
                          </div>
                      </div>
                  </div>
                  <div class="col-lg-6 col-md-6">
                      <div class="news-item-main">
                          <div class="news-items">
                              <div class="testimonial-text">
                                  <p><i class="fa fa-quote-left" aria-hidden="true"></i> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Earum, veritatis, aut. Aperiam quas sunt numquam, quos eaque</p>
                              </div>
                              <div class="testimonial-img">
                                  <div class="user-img">
                                      <img src="assets/image/testimonial3.png" alt="user-img">
                                  </div>
                                  <div class="testimonial-img-text">
                                      <i class="fa fa-star" aria-hidden="true"></i>
                                      <i class="fa fa-star" aria-hidden="true"></i>
                                      <i class="fa fa-star" aria-hidden="true"></i>
                                      <i class="fa fa-star" aria-hidden="true"></i>
                                      <i class="fa fa-star-half-o" aria-hidden="true"></i>
                                      <h4>Alvi Alam</h4>
                                      <span>3 jan, 2019</span>
                                  </div>
                              </div>
                              <div class="clr"></div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</section> -->
<!-- TESTIMONIAL AREA END -->

<!-- FOOTER AREA START -->
<footer id="footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="footer-logo">
                    <a class="f-logo" href="#banner"><b>Neshorn Technologies</b></a>
                    <p>Simplifying as we go.</p>
                    <div class="footer-social">
                        <a href="#"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                        <!-- <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a> -->
                        <a href="mailto:hi@neshorntech.com"><i class="fa fa-envelope" aria-hidden="true"></i></a>
                        <a href="#"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-2 col-sm-6">
                <div class="links">
                    <h3>important links</h3>
                    <ul>
                        <li><a href="#banner">Home</a></li>
                        <!-- <li><a href="#about">About Us</a></li> -->
                        <li><a href="#portfolio">Our Portfolio</a></li>
                        <li><a href="#team">Our Team</a></li>
                        <li><a href="#contact">Contact Us</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-3 col-sm-6">
                <div class="location links">
                    <h3>Our Location</h3>
                    <p><a href="https://goo.gl/maps/znyoNe9ET8Z2kky49" target="_blank">Huizen, Netherlands</a></p>
                    <h3>Our Email</h3>
                    <p>
                        <a href="mailto:hi@neshorntech.com">hi@neshorntech.com</a>
                    </p>
                    <h3>Website</h3>
                    <p><a href="https://neshorntech.com">neshorntech.com</a></p>
                </div>
            </div>
            <!-- <div class="col-lg-4">
              <div class="links pb-2 insta">
                  <h3>Connect Us</h3>
              </div>
              <div class="row">
                  <div class="col-lg-12 insta-item">
                      <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odit minima beatae</p>
                      <form>
                          <div class="form-group fg2">
                              <input type="email" class="form-control" id="exampleInputEmail1" placeholder="Subscribe Now">
                              <button type="submit" class="btn btn-primary"><i class="fa fa-paper-plane-o" aria-hidden="true"></i> Send</button>
                          </div>
                      </form>
                  </div>
              </div>
          </div> -->
        </div>
    </div>
</footer>
<!-- FOOTER AREA END -->

<!-- COPY_RIGHT AREA START -->
<section id="footer-btm">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="fop-btm text-center">
                    <h2>Copyright &copy; 2020. All rights reserved by <a
                            href="https://neshorntech.com">Neshorn Technologies</a></h2>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- COPY_RIGHT AREA END -->