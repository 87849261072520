import { Component, OnInit } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { FormBuilder, Validators } from '@angular/forms';
import { AngularFireFunctions } from '@angular/fire/functions';
import { EmailValidator } from './shared/validators/email.validator';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fade-out', [
      transition('void => *', [
        // style({ opacity: 1 }), 
        animate('800ms 1000ms', style({ opacity: 0, 'z-index': -1 }))
      ])
    ])
  ]
})
export class AppComponent implements OnInit {
  title = 'game-surv-site';
  contactUsForm;
  busySending = false;
  messageSent = false;

  constructor(public formBuilder: FormBuilder, private firebaseFunctions: AngularFireFunctions) {

    this.busySending = false;
    this.messageSent = false;
    
    this.setupContactUsForm();
  }

  setupContactUsForm() {
    this.contactUsForm = this.formBuilder.group({
      name: ['', Validators.compose([Validators.minLength(2), Validators.required])],
      surname: ['', Validators.compose([Validators.minLength(2), Validators.required])],
      email: ['', Validators.compose([Validators.required, EmailValidator.isValid])],
      message: ['', Validators.compose([Validators.minLength(6), Validators.required])]
    });
  }
  ngOnInit() {
    // $('.preload-main').delay(1000).fadeOut(800);

  }

  sendContactUsEmail() {
    this.busySending = true;

    const contactUsEmail = this.firebaseFunctions.httpsCallable('contactUsEmail');
    return contactUsEmail({
      'name': this.contactUsForm.controls.name.value + ' ' + this.contactUsForm.controls.surname.value,
      'email': this.contactUsForm.controls.email.value,
      'message': this.contactUsForm.controls.message.value,
      'site': 'Logix-Intell'
    }).toPromise().then(() => {
      this.busySending = false;
      this.messageSent = true;
      this.setupContactUsForm();
    }).catch(() => {
      this.busySending = false;
    });

  }
}
